@tailwind base;
@tailwind components;
@tailwind utilities;

@base {
  p {
    @apply text-sm font-normal text-neutral-700;
  }

  a:link,
  a:visited,
  a:focus,
  a:active {
    @apply text-blue-500;
  }
}

@layer components {
  /* Button styles */
  .btn {
    @apply inline-flex cursor-pointer select-none items-center justify-center whitespace-nowrap
    font-sans text-sm font-semibold w-fit
    border px-4 py-[5px] transition-all rounded-full disabled:cursor-default disabled:bg-neutral-400 disabled:border-none;
  }

  .btn-primary {
    @apply btn bg-blue-500 text-neutral-100 hover:bg-blue-600 active:bg-blue-700 disabled:bg-neutral-200;
  }

  .btn-primary-white {
    @apply btn bg-neutral-100 text-blue-500 hover:bg-blue-100 active:bg-blue-200 border-none;
  }

  .btn-outline {
    @apply btn bg-transparent text-blue-500 hover:bg-blue-100 active:bg-blue-200;
  }

  .btn-outline-grey {
    @apply btn border-neutral-400 bg-transparent text-neutral-700 hover:bg-neutral-300 active:bg-neutral-400;
  }

  .btn-outline-grey-highlight {
    @apply btn bg-neutral-700 text-neutral-100 hover:bg-neutral-600 active:bg-neutral-400;
  }

  .btn-outline-white {
    @apply btn border-white bg-transparent text-white;
  }

  .btn-ghost {
    @apply btn border-none text-blue-500 hover:bg-blue-100 active:bg-blue-200;
  }

  .btn-size-large {
    @apply py-[9px];
  }

  .btn-size-small {
    @apply py-[5px] px-3 text-xs font-semibold;
  }

  /* Card styles */
  .card {
    @apply rounded-none lg:rounded-lg h-fit w-full p-4 shadow-sm bg-white;
  }

  /* Text styles */
  .title-md {
    @apply text-sm font-bold;
  }

  .title-lg {
    @apply text-base font-bold;
  }

  /* Avatar size */
  .avatar-2 {
    @apply h-2 w-2;
  }

  .avatar-4 {
    @apply h-4 w-4;
  }

  .avatar-6 {
    @apply h-6 w-6;
  }

  .avatar-8 {
    @apply h-8 w-8;
  }

  .avatar-10 {
    @apply h-10 w-10;
  }

  .avatar-12 {
    @apply h-12 w-12;
  }

  .avatar-14 {
    @apply h-14 w-14;
  }

  .avatar-16 {
    @apply h-16 w-16;
  }

  .avatar-40 {
    @apply h-40 w-40;
  }

  /* Divider classes */
  .vertical-divider {
    @apply inline-block align-middle h-4 border-l-[1px] border-solid border-neutral-400;
  }

  .horizontal-divider {
    @apply w-full h-[1px] bg-neutral-400;
  }

  /* List class */
  .list {
    @apply flex flex-col w-full gap-2 items-center;
  }

  /* misc classs */
  .sign-up-card-bg {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      #ffffff 40%
    );
  }

  .markdown-body {
    @apply w-full break-words;

    & a {
      @apply text-blue-500 underline;
    }

    & ol,
    & ul {
      @apply my-4 mx-0;
    }

    & li {
      @apply p-0;
    }

    & ol {
      & li {
        list-style-type: decimal;
        list-style-position: outside;
        margin-left: 30px;
      }
    }

    & ul {
      & li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 30px;
      }
    }

    & img {
      @apply max-w-full;
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .comment-body {
    & ol {
      @apply mb-0;
    }

    & p:first-child {
      margin-block-start: 0;
    }
  }

  .post-desc-body {
    @apply text-neutral-600;
  }

  .about-content {
    & p {
      margin-top: 1.5em;
    }

    & p:first-child {
      margin-top: 0px;
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-top: 1.5em;
      font-weight: bold;
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6:first-child {
      margin-top: 0px;
      font-weight: bold;
    }
  }

  .jumbotron {
    @apply w-full 
    flex flex-col items-center justify-center
    min-h-[280px]
    bg-blue-500
    bg-center
    bg-cover
    bg-no-repeat;
  }

  .product-detail-section {
    @apply mb-8 p-0 space-y-4;
  }
}

@layer utilities {
  .center-in-div {
    @apply flex items-center justify-center;
  }

  .avatar-background {
    background: linear-gradient(180deg, #2955de 50%, white 50%);
  }

  .desktop-only {
    @apply max-lg:hidden max-lg:w-0 max-lg:h-0 max-lg:m-0;
  }

  .mobile-only {
    @apply lg:hidden lg:w-0 lg:h-0 lg:m-0;
  }
}
